import React, { useState, useEffect } from 'react';
import openedUnit from 'assets/images/opened-unit.png';
import openedUnitWebp from 'assets/images/opened-unit.png?as=webp';
import LazyLoad from 'react-lazyload';
import loadable from '@loadable/component';
import { EMOJI } from '../../../shared/enums';

const FadeIn = loadable(() => import('../common/FadeIn'));

/**
 * @returns {JSX.Element}
 * @constructor
 */
const HeroBlock = () => {
  const [ isImgLoading, setIsImgLoading ] = useState(false);
  const [ awaitImgLoad, setAwaitImgLoad ] = useState(false);

  useEffect(() => {
    if (!isImgLoading) return;
    const timer = setTimeout(() => {
      setAwaitImgLoad(true);
    }, 500);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [ isImgLoading ]);

  return (
    <section className='hero hero--inner hero--wave-bg'>
      <div className='container'>
        <h1 className='hero__title'>
          A big brain
          {' '}
          {EMOJI.BRAIN}
          {' '}
          and the muscle
          {' '}
          {EMOJI.FLEXED_BICEPS}
          {' '}
          to back it up.
        </h1>
        <p className='hero__description'>
          Get a closer look at the powerful air purifier that can actually stand behind its
          claims.
        </p>
        <figure className='hero__image img-relative'>
          {!awaitImgLoad && <div className='image-placeholder' style={{ aspectRatio: `${880 / 744}` }} />}
          <LazyLoad className={`${!awaitImgLoad ? 'img-absolute' : ''}`}>
            <FadeIn>
              <picture>
                <source
                  srcSet={openedUnitWebp}
                  type='image/webp'
                />
                <source
                  srcSet={openedUnit}
                  type='image/png'
                />
                <img
                  src={openedUnit}
                  alt='Mila black edition'
                  onLoad={() => setIsImgLoading(true)}
                />
              </picture>
            </FadeIn>
          </LazyLoad>
        </figure>
      </div>
    </section>
  );
};

export default HeroBlock;
